import React from 'react';
import styles from './styles/privacypolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading1}>Privacy Policy</h1>
      <p className={styles.paragraph}><strong>Last updated:</strong> [04-09-2024]</p>

      <p>Welcome to Astroping. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [https://astroping.com/], use our services, or engage with us in other ways. Please read this policy carefully.</p>

      <h2 className={styles.heading2}>1. Information We Collect</h2>
      <div className={styles.informationSection}>
        <p><strong>Personal Data:</strong> We may collect personal information such as your name, email address, phone number, and payment information when you use our services.</p>
        <p><strong>Usage Data:</strong> We collect information about how you interact with our website, including your IP address, browser type, pages visited, and the dates and times of your visits.</p>
        <p><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience on our site. These technologies help us remember your preferences and track usage patterns.</p>
      </div>

      <h2 className={styles.heading2}>2. How We Use Your Information</h2>
      <p>We use the collected information to:</p>
      <ul className={styles.list}>
        <li className={styles.listItem}>Provide and improve our services.</li>
        <li className={styles.listItem}>Process transactions and manage your orders.</li>
        <li className={styles.listItem}>Communicate with you, including sending updates, marketing materials, and support messages.</li>
        <li className={styles.listItem}>Analyze usage patterns to enhance our website and services.</li>
      </ul>

      <h2 className={styles.heading2}>3. Sharing Your Information</h2>
      <p>We may share your information with:</p>
      <ul className={styles.list}>
        <li className={styles.listItem}><strong>Service Providers:</strong> Third-party vendors who assist us in operating our website and providing services.</li>
        <li className={styles.listItem}><strong>Legal Requirements:</strong> Authorities if required to comply with legal obligations or protect our rights.</li>
        <li className={styles.listItem}><strong>Business Transfers:</strong> In connection with a merger, acquisition, or sale of assets.</li>
      </ul>

      <h2 className={styles.heading2}>4. Data Security</h2>
      <p>We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no system can be completely secure, and we cannot guarantee the absolute security of your data.</p>

      <h2 className={styles.heading2}>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul className={styles.list}>
        <li className={styles.listItem}>Access and update your personal information</li>
        <li className={styles.listItem}>Request the deletion of your data</li>
        <li className={styles.listItem}>Opt out of receiving marketing communications</li>
      </ul>
      <p>To exercise these rights, please contact us at [ Vishal@Kilope.com ].</p>

      <h2 className={styles.heading2}>6. Cookies</h2>
      <p>You can manage cookies through your browser settings. Disabling cookies may affect the functionality of our website.</p>

      <h2 className={styles.heading2}>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website. Your continued use of our services after such changes constitutes your acceptance of the new policy.</p>

      
    </div>
  );
}

export default PrivacyPolicy;
