import React from 'react';
import styles from './styles/contactus.module.css';

const ContactUs = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading1}>Contact Us</h1>
      <p className={styles.paragraph}><strong>We’d love to hear from you!</strong> Please use the following methods to get in touch with us.</p>

      <h2 className={styles.heading2}>1. Contact Information</h2>
      <p><strong>Email:</strong> <a href="Vishal@Kilope.com" className={styles.link}>Vishal@Kilope.com</a></p>
      <p><strong>Phone:</strong>+12345292539</p>
      <p><strong>Address:</strong> 3rd Floor CoWork Walley,MMS Divya Diamonds Junction,Kavuri Hills phase,2nd Road Hyderabad,Telangana 500033..</p>

      <h2 className={styles.heading2}>2. Contact Form</h2>
      <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" className={styles.input} required />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" className={styles.input} required />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" className={styles.textarea} required></textarea>
        </div>
        <button type="submit" className={styles.button}>Send Message</button>
      </form>

      <h2 className={styles.heading2}>3. Find Us</h2>
      <div className={styles.mapContainer}>
        <p>We are located at the following address:</p>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12601.648303116442!2d-122.4194155709604!3d37.774929934751264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808b2e5e223f%3A0x5f8c8ed2b5d7f4e!2sSan%20Francisco%2C%20CA!5e0!3m2!1sen!2sus!4v1627595532262!5m2!1sen!2sus"
          className={styles.map}
          title="Map showing the location of Astroping"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactUs;
