import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeeScreeen from './HomeeScreeen';
import TermsandConditions from './TermsandConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Refundpolicy from './Refundpolicy';
import ContactUs from './Contactus';
 import Disclaimer from './Disclaimer';

const Routing = () => (
    <Router>
    <Routes>
      <Route path="/" element={<HomeeScreeen />} />
      <Route path="/terms" element={<TermsandConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/refund" element={<Refundpolicy />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
    </Routes>
  </Router>
);

export default Routing;
