import React from 'react'
import styles from './styles/Homeescreen.module.css'
import user from './images/User.png'
import messageicon from './images/Chat Bubble.png'
import callicon from './images/Phone.png'
import carticon from './images/Shopping Cart.png'
import flowericon from './images/Flower Vase.png'
import chakra from './images/chakra.jpeg'
import dailyhoro from './images/dailyhoroscope.jpeg'
import kundlimatch from './images/kundlimatching.jpeg'
import panchang from './images/panchang.jpeg'
import compatability from './images/compatability.jpeg'
import instagram from './images/instagram.png'
import facebook from './images/Facebook.png'
import linkedin from './images/linkedin.png'
import youtube from './images/youtube.png'
import { useNavigate } from 'react-router-dom';
import newlogoastroping from './images/newlogoastroping.png'

function HomeeScreeen() {
     
            const handleClick = () => {
              window.open('https://docs.google.com/forms/d/e/1FAIpQLSdVwwSmY-hSdsw03jU3ZjKFASNAUZ9G1wK_iF_wbyB33dNSAw/viewform?usp=sf_link', '_blank');
            };


            const navigate = useNavigate();

             const termsandconditions = () =>{
                  navigate('/terms');
             }

             
             const privacypolicy=()=>{
                  navigate('/privacy')
             }

             const refundpolicy=()=>{
                  navigate('/refund')
             }

             
             const contactus=()=>{
                  navigate('/contactus')
             }


             const disclaimer=()=>{
                  navigate('/disclaimer')
             }



  return (
    <div className={styles.maincontainer}>

          <div className={styles.headercontainer}>
               
                <div className={styles.subcontaineroneofheaderconatiner}>
                     <img src={newlogoastroping} alt="" id={styles.horoscope}/>
                     <h1>Astroping</h1>
                </div>
                <div className={styles.subcontainertwoofheaderconatiner}>
                    <h1>Free Kundli</h1>
                    <h1>Kundli Matching</h1>
                    <h1>Chat With Astrologer</h1>
                    <button id={styles.buttonforlogin}><img src={user} alt="" id={styles.setimageinsideloginbutton}/>Login</button>
                </div>
          </div>


          <div className={styles.bodycontainerone}>

                  <div className={styles.childcontoneofbodycontone}>
                     <img src={chakra} alt="" id={styles.idforastrologerimage}/>
                    <div className={styles.classforchatnow}>
                      <h1>Chat With Astrologer</h1>
                      <button id={styles.buttonforchatnow}>Chat Now</button>
                    </div>  
                  </div>

                  <div className={styles.childconttwoofbodycontone}>
                       <div className={styles.subchildconttwoofbodycontone}> 
                            <img src={messageicon} alt="" />
                             <h1>Chat With Astrologer</h1>
                       </div>
                       <div className={styles.subchildconttwoofbodycontone}> 
                            <img src={callicon} alt="" />
                            <h1>Call to Astrologer</h1>
                      </div>
                      <div className={styles.subchildconttwoofbodycontone}> 
                            <img src={carticon} alt="" />
                            <h1>Shopping Cart</h1>
                      </div>
                      <div className={styles.subchildconttwoofbodycontone}> 
                            <img src={flowericon} alt="" />
                            <h1>Book A Pooja</h1>
                      </div>
                  </div>
                
          </div>


          <div className={styles.bodycontainerthree}>
                <h1>Astroping Services</h1>
                <div className={styles.childcontofbodycontthree}>
                      <div className={styles.subchildofchildcontofbodycontthree}>
                           <div className={styles.supersubchildofbodycontainerthree}>
                                     <img src={dailyhoro} alt="" id={styles.idtosetcardimages}/>
                                     <h1>Daily Horoscope</h1>
                                     <span id={styles.firstidforspan}>Reading your Today’s horoscope is one of the best ways to predict your future. The foretelling of the future through the Daily horoscope is an ancient practice and finds relevance even today...... </span>
                           </div>
                      </div>

                      <div className={styles.subchildofchildcontofbodycontthree}>
                             <div className={styles.supersubchildofbodycontainerthree}>
                                    <img src={kundlimatch} alt="" id={styles.idtosetcardimages}/> 
                                    <h1>Kundli Matching</h1>
                                    <span id={styles.firstidforspan}>Looking for your free Kundli from expert astrologers? Then you have come to the right place. The online free kundali available on Astrotalk is a 100% free and authentic free Kundli... </span>
                             </div>    
                      </div>

                      <div className={styles.subchildofchildcontofbodycontthree}>
                             <div className={styles.supersubchildofbodycontainerthree}>
                                    <img src={panchang} alt="" id={styles.idtosetcardimages}/>
                                    <h1>Ramana Panchang</h1>
                                    <span id={styles.firstidforspan}>Majorly, Panchang is used to get detail of five aspects. These five aspects are - day of the week (vaar); tithi or the lunar day; nakshatra or the constellation; yoga; and karan......</span>
                             </div>
                      </div>

                      <div className={styles.subchildofchildcontofbodycontthree}>
                             <div className={styles.supersubchildofbodycontainerthree}>
                             <img src={compatability} alt="" id={styles.idtosetcardimages}/>
                             <h1>Compatability</h1>
                             <span id={styles.firstidforspan}>You don't always get along like a blaze on flames with people, but when you're with that "special person," you feel happy and in control of the situation. We encounter numerous people  life........</span>
                             </div>
                      </div>
                </div>
          </div>


          <div className={styles.bodycontainerfour}>
               <div className={styles.childoneofbodycontainerfour}>
                   <h1>27+ MIllion </h1>
                   <span>Total Customers</span>
               </div>
               <div className={styles.childoneofbodycontainerfour}>
                   <h1>25,000+</h1>
                   <span>Total astrologers</span>
               </div>
          </div>
          

          <div className={styles.bodycontainerfive}>
                  <div className={styles.childcontofbodycontainerfive}>
                     <h1 id={styles.idfornameourastrologers}>Our  Astrologers</h1>
                     <div className={styles.subchildcontofbodycontainerfive}>
                           <div className={styles.classnameforcard}>
                                  <img src={user} alt="" />
                                  <h1>Suresh</h1>
                                  <span  id={styles.panchanpandit}> Panchang Pandit</span>
                           </div>
                           <div className={styles.classnameforcard}>
                                   <img src={user} alt="" />
                                   <h1>Ramesh</h1>
                                   <span id={styles.panchanpandit}> Panchang Pandit</span>
                           </div>
                           <div className={styles.classnameforcard}>
                                   <img src={user} alt="" />
                                   <h1>Ganesh</h1>
                                   <span id={styles.panchanpandit}> Panchang Pandit</span>
                           </div>
                     </div>
                  </div>
          </div>


          <div className={styles.bodycontainersix}>
                <div className={styles.childcontofbodycontainersix}>
                      <h1>Why HoroScope</h1>
                      <span style={{color:'grey',fontWeight:'bold'}}>A horoscope (or other commonly used names for the horoscope in English include natal chart, astrological chart, astro-chart, celestial map, sky-map, star-chart, cosmogram, vitasphere, radical chart, radix, chart wheel or simply chart) is an astrological chart or diagram representing the positions of the Sun, Moon, planets, astrological aspects and sensitive angles at the time of an event, such as the moment of a person's birth. </span>
                      <span style={{color:'grey',fontWeight:'bold'}}>The word horoscope is derived from the Greek words ōra and scopos meaning "time" and "observer" (horoskopos, pl. horoskopoi, or "marker(s) of the hour").</span>
                      <span style={{color:'grey',fontWeight:'bold'}}>It is claimed by proponents of astrology that a horoscope can be used as a method of divination regarding events relating to the point in time it represents, and it forms the basis of the horoscopic traditions of astrology, although practices surrounding astrology have been recognized as pseudoscientific since the 18th century.[1] Horoscope columns are often featured in print and online newspapers.[2]In common usage, horoscope often refers to an astrologer's interpretation, usually based on a system of solar Sun sign astrology; based strictly on the position of the Sun at the time of birth, or on the calendar significance of an event, as in Chinese astrology.</span>
                      <span style={{color:'grey',fontWeight:'bold'}}>In particular, many newspapers and magazines carry predictive columns, written in prose that may be written more for increasing readership than tied directly to the Sun or other aspects of the Solar System, allegedly based on celestial influences in relation to the zodiacal placement of the Sun on the month of birth, cusp (two days before or after any particular sign, an overlap), or decant (the month divided into three ten-day periods) of the person's month of birth, identifying the individual's Sun sign or "star sign" based on the tropical zodiac.</span>
                      <span style={{color:'grey',fontWeight:'bold'}}>The plane of the equator is the plane of the Earth's equator projected into space.The plane of the ecliptic is defined by the orbits of the Earth and the Sun. For practical purposes, the plane of the equator and the plane of the ecliptic maintain a constant inclination to each other of approximately 23.5°.The plane of the horizon is centred on the native, and is tangential to the Earth at that point. In a sphere whose radius is infinitely large, this plane may be treated as nearly equivalent to the parallel plane, with its centre at the Earth's centre. This greatly simplifies the geometry of the horoscope, but does not take into account that the native is in motion. Some writers on astrology have thus considered the effects of parallax, but most would agree that (apart from that of the Moon) they are relatively minor.[citatio]Generally, on an astrological chart, each of these four angles are roughly 90° from the next, forming a cross shape (two oppositions, 180° each, forming a 360° sphere). This cross formation is made up of the points of east-west, north-south, or 1st house-7th house, 10th house-4th house (give or take, based on speed of orbit and degree). A simplistic comparison would be a clock face, with the 1st house and 7th house being placed at 9 and 3 o'clock, and the 10th and 4th houses placed at 12 and 6 o'clock, respectively</span>
                      <span style={{color:'grey',fontWeight:'bold'}}>The placement of the planetary ruler of the ascendant, called the Chart Ruler, is also considered to be significant; The point in the west diametrically opposing the ascendant is called the descendant, normally the cusp of the 7th house; and the point opposing the MC is the cusp of the 4th house, the northernmost point of the chart, called the Imum Coeli or IC.</span>
                </div>
          </div>


          <div className={styles.footercontainer}>
                  <div className={styles.childcontoffootercontainer}>

                        <div className={styles.leftcontofchildcontoffootercontainer}>
                               <img src={newlogoastroping} alt="" style={{height:'100px',width:'100px'}} id={styles.idnameformedia}/>
                               <div className={styles.subchildofleftcont}>
                                  <h1>Astroping</h1>
                                  <span  id={styles.companyaddress}>3rd Floor CoWork Walley,MMS Divya Diamonds Junction,Kavuri Hills phase,2nd Road Hyderabad,Telangana 500033.......</span>
                                  <div className={styles.classNameforalsoavailablein}>
                                      <img src={instagram} alt="" style={{height:'27px',width:'27px'}}/>
                                      <img src={facebook} alt="" style={{height:'27px',width:'27px'}}/>
                                      <img src={linkedin} alt="" style={{height:'27px',width:'27px'}}/>
                                      <img src={youtube} alt="" style={{height:'27px',width:'27px'}}/>
                                  </div>
                               </div>
                        </div>

                        <div className={styles.rightcontofchildcontoffootercontainer}>
                              <div className={styles.impotant_links}>
                                      <h1 id={styles.idforquery}>Important Links</h1>
                                      <span>Collaboration</span>
                                      <span>Zodiac Signs</span>
                                      <span>Vastu Shastra</span>
                                      <span>Torat</span>
                                      <span>Love Caluculator</span>
                                      <span>Mole Astrology</span>
                                      <span>Festival Calendar</span>
                                      <span>Solar Eclipse 2024</span>
                                      <span>Lunar Eclipse 2024</span>
                              </div>
                              <div className={styles.impotant_links}>
                                  <h1 id={styles.idforquery}>Astrologer</h1>
                                  <span>Astrologer Login</span>
                                  <span onClick={handleClick} style={{textDecoration: 'underline' }}>Astrologer Registration</span>
                                  <h1 id={styles.idforquery}>Corporate Info</h1>
                                  <span onClick={termsandconditions} style={{textDecoration:'underline'}}>Terms And Conditions</span>
                                  <span onClick={contactus} style={{textDecoration:'underline'}}>ContactUs </span>
                                  <span onClick={refundpolicy} style={{textDecoration:'underline'}}>Refund Policy</span>
                                  <span  onClick={disclaimer} style={{textDecoration:'underline'}}>Disclaimer</span>
                                  <span onClick={privacypolicy} style={{textDecoration:'underline'}}>Privacy Policy</span>
                              </div>
                              <div className={styles.impotant_links}>
                              <h1 id={styles.idforquery}>HoroScope</h1>
                              <span>Daily Horoscope</span>
                              <span>Yesterday's Horoscope</span>
                              <span>Weekly HoroScope</span>
                              <span>Tomorrow's Horoscope</span>
                              <span>Yearly Horoscope</span>
                              <span>Monthly Horoscope</span>
                              <span>Collaboration Horoscope</span>
                              </div>
                        </div>
                        
                  </div>

                  <div className={styles.classNameforcopyrighttext}>
                       <span>Copyright &copy; 2024 Astroping all rights reserved</span>
                  </div>
          </div>



    </div>
  )
}

export default HomeeScreeen;